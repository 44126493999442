import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageHead } from '../common'
import Contact from '../components/contactForm'
import Location from '../sections/locations'
import JoinUs from '../sections/joinUs'
import { md, colors } from '../common'


export default () => (
  <Layout>
    <SEO
      title="Contact Us"
      description="" />
    <PageHead>
      <h1>Contact Us</h1>
    </PageHead>
    <div css={`width:50%;margin:auto;padding-bottom:4rem;${md}{width:88%;margin:1rem;background-color:${colors.greyLight}};padding:5px;`}>
      <Contact />
    </div>
    <br />
    <Location />
    <JoinUs />
  </Layout>
)
